<template>
    <section class="wh-100 vert-scroll mobile-profile" style="padding: 0 0 9px 0;">
        <div class="profile-user-icon my-5">
            <i class="user el-icon-user-solid" ></i>
        </div>
        <el-row class="text-center mb-3">
            <div>{{user.name}}</div>
        </el-row>
        <el-row class="text-center mb-3">
            <div>{{user.user_id}}</div>
        </el-row>
        <el-row class="text-center mb-3">
            <div>{{user.department}} <span v-if="user.city">| {{user.city}} </span></div>
        </el-row>
        <el-divider class="mb-3"> </el-divider>

        <nut-button v-if="$organization.application" block style="margin-bottom:5px; font-size: 16px; height: 48px;"
        @click="$router.push('/application')">{{$t('TITLE.APPLICATION')}}</nut-button>
        <nut-button @click="signout" block style="font-size: 16px; height: 48px;">{{$t('TITLE.SIGN-OUT')}}</nut-button>

    </section>
</template>
<script>
import PortalMixin from "@/views/mixins/mobile-portail-mixin";

export default {
    name: "mobile-profile",
    mixins: [PortalMixin],
    mounted() {
    },
    methods:{
        loadEvent(){
        },
        signout(){
            const vm = this;
            this.$dialog({
                id: 'SIGN-OUT',
                content: this.$t('MESSAGE.CONFIRM-QUIT'),
                okBtnTxt:this.$t('TITLE.CONFIRM'),
                cancelBtnTxt:this.$t('TITLE.CANCEL'),
                onOkBtn() {  //确定按钮点击事件
                    vm.clear();
                    const return_url = vm.$organization.signin_url;
                    if (vm.$organization.sso_out) {
                        window.location.href = vm.$organization.sso_out + encodeURIComponent(return_url); // cas 跳转
                    } else {
                        if (vm.$organization.oauth_out) {
                            window.location.href = vm.$organization.oauth_out + encodeURIComponent(return_url);
                        } else {
                            vm.$router.replace({name:'sign-in'});
                        }
                    }
                    this.close(); //关闭对话框
                },
            });

        }
    }
}
</script>
<style lang="scss">
.mobile-profile {
    .profile-user-icon {
        width: 128px;
        height: 128px;
        border-radius: 128px;
        background-color: white;
        border: 3px solid  lightgray;
        margin: auto;
        text-align: center;

        .user {
            color: $--public-highlight;
            font-size: 100px;
            line-height: 120px;
        }
    }
}
</style>
